var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-box" },
    [
      _c("i-header", {
        attrs: {
          title: _vm.config["title"] ? _vm.config["title"].template : "",
          showBack: true,
        },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _c(
            "i-form",
            {
              ref: "ruleForm",
              staticClass: "cnt-list",
              attrs: { "scroll-to-error": "" },
              on: { submit: _vm.onSubmit },
            },
            [
              _vm.totalPoints
                ? _c(
                    "div",
                    { staticClass: "total_points" },
                    [
                      _c("div", [
                        _vm._v(" " + _vm._s(_vm.totalPoints.title) + "5分 "),
                        _c("p", [_vm._v("满分5分")]),
                      ]),
                      _c("i-rate", {
                        attrs: { color: "#ffcd30", size: 24 },
                        on: {
                          change: function ($event) {
                            return _vm.onTotalChange(_vm.item, _vm.index)
                          },
                        },
                        model: {
                          value: _vm.totalPoints.score,
                          callback: function ($$v) {
                            _vm.$set(_vm.totalPoints, "score", $$v)
                          },
                          expression: "totalPoints.score",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.template, function (item, index) {
                return _c(
                  "div",
                  { key: "ruleForm" + index },
                  [
                    _c("div", { staticClass: "cnt-title" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c(
                      "div",
                      [
                        _c("i-rate", {
                          attrs: { color: "#ffcd30", size: 24 },
                          on: {
                            change: function ($event) {
                              return _vm.onChange(item, index)
                            },
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                        _c("span", { staticClass: "rate-name" }, [
                          _vm._v(_vm._s(_vm.scoreStatusParams[item.value])),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "opt_item" },
                      _vm._l(item.options, function (optList) {
                        return _c(
                          "div",
                          {
                            key: optList.optIndex,
                            staticClass: "opt_list",
                            class: optList.isOpt ? "selectStar" : "",
                            on: {
                              click: function ($event) {
                                return _vm.chooseOpt(optList)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(optList.text) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("iFormItem", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.show,
                          expression: "item.show",
                        },
                      ],
                      attrs: {
                        "model-val": item.message,
                        "form-item": item,
                        type: "textarea",
                        formData: _vm.paramsData,
                        formTemplate: item,
                        rules: item.rules,
                      },
                      on: { callBack: _vm.formCallBack },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "i-button",
            {
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                "loading-text": "提交",
                block: "",
                round: "",
                type: "primary",
                size: "normal",
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" 提交 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }