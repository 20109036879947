<template>
    <div class="add-box">
        <i-header :title="config['title'] ? config['title'].template : ''" :showBack="true" @back-event="toBack"></i-header>
        <div class="cnt-box">
                <i-form ref="ruleForm" @submit="onSubmit" scroll-to-error  class="cnt-list">
                    <div class="total_points" v-if="totalPoints">
                        <div>
                            {{ totalPoints.title }}5分
                            <p>满分5分</p>
                        </div>    
                        <i-rate v-model="totalPoints.score" color="#ffcd30" :size="24"  @change="onTotalChange(item,index)"/>
                    </div>
                    <div v-for="(item,index) in template" :key="'ruleForm' + index">
                        <div class="cnt-title">{{ item.name }}</div>
                        <div>
                            <i-rate v-model="item.value" color="#ffcd30" :size="24"  @change="onChange(item,index)"/>
                            <span class="rate-name">{{ scoreStatusParams[ item.value] }}</span>
                        </div>
                        <div class="opt_item">
                            <div v-for="optList in item.options" :key="optList.optIndex" class="opt_list" @click="chooseOpt(optList)" :class="optList.isOpt?'selectStar':''">
                                {{optList.text }}
                            </div>
                        </div>
                        <iFormItem :model-val="item.message"  @callBack="formCallBack" :form-item="item" type="textarea" 
                        :formData="paramsData" :formTemplate="item" v-show="item.show" :rules="item.rules"></iFormItem>
                    </div>
                </i-form>
            <i-button :loading="loading" @click="submit" :disabled="loading" loading-text="提交" block round type="primary" size="normal"> 提交 </i-button>
        </div>     
    </div>
</template>

<script>
import generic from "../../utils/generic";
import iFormItem from "@/components/iFormItem/iFormItem";

export default {
    name: "index",
    components: {iFormItem},
    data() {
        return {
            title: "APP评分",
            value: 3,
            loading: false,
            config: {},
            rateList:[],
            paramsData:[],
            scoreStatusParams: {},
            baseData:[],
        };
    },
    created() {
        // this.getConfig();  
        this.getSurvay();    
    },
    methods: {
        getSurvay(){
            let params = {
                type: 2,
            };
            this.$service.getSurvay(params).then((res) => {
                this.cityList = res.content || res;
            });
        },  
        getConfig() {
            generic.getConfigs({
                miceId: this.$route.query.miceId,
                group: this.$route.query.type,
                router: this.$route.path
            },
                Object.assign(this.$smartStorage.get('miceInfo')))
                .then((config) => {
                    this.config = config;
                    console.log(' this.config==', this.config)
                    for (const configKey in config) {
                        if (config[configKey].callback) this[config[configKey].callback]()
                    }
                });
        },
        async init(){
            this.template = JSON.parse(JSON.stringify(this.config["listCard"].template.rateList));
            this.scoreStatusParams= this.config["listCard"].template.scoreStatusParams;
            this.totalPoints= this.config["listCard"].template.totalPoints;
        },
        toBack() {
            this.$router.back();
        },
        onChange(item){
            console.log('item==',item)
            if(this.template.length==0){
                this.template= this.config["listCard"].template.rateList;
            }
            const array = this.template.map((ele,index)=>{
                if(ele.controlName==item.controlName){
                    this.config["listCard"].template.rateList[index].value=item.value;
                    ele = generic.replaceEvalObject(this.config["listCard"].template.rateList[index],item);
                }
                return ele;
            })
            this.template=array;
            this.paramsData  = JSON.parse(JSON.stringify(this.template, ['message', 'value','controlName']))
            this.$forceUpdate();
        },
        formCallBack(value, index, formItem){
            this.template[index].message=value;
        },
        chooseOpt(optList){
            optList.isOpt=true;
            this.$forceUpdate();
            this.paramsData  = JSON.parse(JSON.stringify(this.template, ['message', 'value','controlName']))
        },
        submit(){
            this.$refs.ruleForm.validate().then(() => {
                this.$refs.ruleForm.submit();
            }).catch(() => {
                this.$itoast('请完善必填信息')
            })
        },
        onSubmit() {
            this.loading = true;
            let params ={
                miceId: this.$route.query.miceId,
                QuestionList: this.paramsData
            }
            this.$service.SaveSurvay(params).then((res) => {
                this.loading = false;
                if (res && res.success) {
                    this.$router.back();
                }else{
                    this.$itoast.fail("订单错误,请联系管理员！");
                }
            });
    },
    },
};
</script>

<style lang="less" scoped>
@textColor: var(--themeColor);
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 .18rem;
        box-sizing: border-box;
        .cnt-title {
            font-size: .15rem;
            margin: .1rem;
        }
    .total_points{
        width: 100%;
        overflow: hidden;
        border-radius: 12px;
        -webkit-box-shadow: 0 4px 16px 1px #e5e5e5;
        box-shadow: 0 4px 16px 1px #e5e5e5;
        padding: .18rem 0.25rem;
        margin-bottom: .1rem;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // position: relative;
    }    
    .cnt-list {
        margin: .18rem 0;
        div{
            // margin-bottom: .1rem;
        }
        /deep/.van-cell {
            border:1px solid #ccc;
        }
    }
        /deep/ .van-button__text {
            font-size: 0.16rem;
        }
    }
    .rate-name{
        color:#ffcd30;
        margin-left: .1rem;
    }
    .opt_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: .1rem;
    }
    .opt_list{
            padding: .06rem .08rem;
            border-radius: .04rem;
            background: #ccc;
            color: #fff;
            margin: .05rem;
            min-width: .7rem;
            text-align: center;

    }
    .selectStar{
        background: var(--themeColor);
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
    .van-field__control textarea::placeholder {
        font-size: 0.13rem !important;
    }
    /deep/ .van-field__body {
        textarea{
            background: #fff;
        }
        &::-webkit-input-placeholder {
            font-size: .13rem;  
        }
        
    }
}
</style>

